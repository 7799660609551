@tailwind base;
@tailwind components;
@tailwind utilities;
@import "atropos/scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");

body {
  background-color: #f7fafc;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
}
