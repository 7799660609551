@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.animate-slide-left {
  animation-name: slideLeft;
  animation-duration: 0.7s;
}

@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.animate-slide-right {
  animation-name: slideRight;
  animation-duration: 0.7s;
}

@keyframes slideUp {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation-name: slideUp;
  animation-duration: 0.7s;
}
